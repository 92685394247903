@import '@aurora/shared-client/styles/_variables.pcss';
@import '@aurora/shared-client/styles/mixins/_typography.pcss';

/* Wrapping container styles */
.lia-container {
  display: flex;
  flex-direction: column;

  &-alignment {
    &-left {
      align-items: flex-start;
    }

    &-center {
      align-items: center;
    }

    &-right {
      align-items: flex-end;
    }
  }
}

/* Layout styles */
.lia-header {
  &-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  &-grid {
    display: grid;
    grid-column-gap: 80px;

    .lia-search {
      margin: 0;
    }
  }
}

/* Alignment styles for Small and Large variants */
.lia-alignment {
  &-left {
    align-items: flex-start;
    text-align: left;

    .lia-footer-wrap {
      @media (--lia-breakpoint-up-md) {
        justify-content: flex-start;
      }
      @media (--lia-breakpoint-down-sm) {
        align-items: flex-start;
      }
    }
  }

  &-center {
    align-items: center;
    text-align: center;

    .lia-footer-wrap {
      @media (--lia-breakpoint-up-md) {
        justify-content: center;
      }
      @media (--lia-breakpoint-down-sm) {
        align-items: center;
      }
    }
  }

  &-right {
    align-items: flex-end;
    text-align: right;

    .lia-footer-wrap {
      @media (--lia-breakpoint-up-md) {
        justify-content: flex-end;
      }
      @media (--lia-breakpoint-down-sm) {
        align-items: flex-end;
      }
    }
  }
}

.lia-content-width {
  @media (--lia-breakpoint-up-lg) {
    &-wide {
      width: 75%;
    }
    &-medium {
      width: 66.66%;
    }
    &-narrow {
      width: 50%;
    }
  }
}

/* Layout styles for Medium variant */
.lia-layout {
  &-equal-width {
    grid-template-columns: 1fr 1fr;
  }
  &-wide-left {
    grid-template-columns: 2fr 1fr;
  }
  &-wide-right {
    grid-template-columns: 1fr 2fr;
  }

  @media (--lia-breakpoint-down-sm) {
    &-equal-width,
    &-wide-left,
    &-wide-right {
      grid-template-columns: 1fr;
    }
  }
}

/* Alignment styles for Medium variant */
.lia-col {
  &-left {
    grid-column: 1;
    align-items: flex-start;
    @media (--lia-breakpoint-down-sm) {
      &:not(:first-child) {
        margin-top: 20px;
      }
      + .lia-col-right {
        margin-top: 25px;
      }
    }
  }

  &-right {
    grid-column: 2;
    align-items: flex-end;
    @media (--lia-breakpoint-down-sm) {
      grid-column: 1;
    }
  }

  &-left,
  &-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (--lia-breakpoint-down-sm) {
      align-items: center;
      text-align: center;
    }
  }
}

/* Medium variant on a Community Page when on mobile */
@media (--lia-breakpoint-down-sm) {
  .lia-spacer {
    margin-top: -15px;
  }
}

/* Individual element styles */
.lia-avatar {
  margin-right: 20px;
  border-radius: var(--lia-bs-border-radius-sm);
  flex-shrink: 0;

  @media (--lia-breakpoint-down-sm) {
    margin-right: 0;
  }
}

.lia-title {
  margin-bottom: 0;
  height: fit-content;
  word-break: break-word;
  @include heading-defaults(h2);

  @media (--lia-breakpoint-down-sm) {
    @include heading-defaults(h4);
  }
}

/* Adds margin between title and description */
.lia-description:not(:first-child) {
  margin-top: 5px;
}

/* Adds margins between title/description and other elements  */
.lia-title,
.lia-description {
  + .lia-search,
  + .lia-footer-wrap {
    margin-top: 25px;
  }
  + .lia-stats,
  + .lia-footer-wrap.lia-no-button {
    margin-top: 10px;
  }
}

.lia-search {
  min-width: 100%;
  text-align: left;

  &:not(:last-child) {
    margin-bottom: 25px;
  }
}

.lia-stats {
  display: inline-flex;
  align-items: center;
  justify-content: inherit;
  color: currentColor;

  &:hover,
  &:focus {
    color: currentColor;
    text-decoration: none;
  }
}

.lia-count {
  font-size: var(--lia-bs-font-size-sm);
  font-weight: var(--lia-bs-font-weight-bold);
}

@media (--lia-breakpoint-down-sm) {
  .lia-btn {
    min-width: 100%;
  }
}

/* Button and stat counts wrapper styles */
.lia-footer-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 25px;
  row-gap: 20px;
  width: 100%;

  @media (--lia-breakpoint-up-md) {
    flex-direction: row;
    align-items: center;

    &.lia-align-right {
      justify-content: flex-end;
    }
  }
}

.lia-node-header-edit-mode {
  padding: 25px 15px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    inset: 25px 0;
    border: 1px dashed var(--lia-bs-gray-500);
    border-radius: var(--lia-bs-border-radius);
    pointer-events: none;
    max-width: inherit;
    margin-right: auto;
    margin-left: auto;
  }
}
